import React from 'react';
import Carousel from 'react-responsive-carousel/lib/js/components/Carousel/index';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-responsive-carousel/lib/styles/carousel.css';

const CarouselPage = () => {
  return (
    <Carousel autoPlay={true} infiniteLoop={true} animationHandler="fade" transitionTime="3000" swipeable={false} showThumbs={false} showStatus={false}>
      <div>
         <img src="images/b0.jpg" className="cour" />
         
       </div>
       <div >
         <img src="images/b1.jpg"  className="cour" />
         
       </div>
      <div>
      <img src="images/b2.jpg"   className="cour" />
      
     </div>
	 <div>
      <img src="images/b3.jpg"   className="cour" />
      
     </div>
   </Carousel>
  );
};
export default CarouselPage