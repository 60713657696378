import React from 'react';

function Footer() {
  return (
    <div className="innerth">    
    <hr className="new1" />

    <div className="row">
    
    
    
    <div className="col-md-10  col-md-offset-1" align="center">
    <font className="bottommenu1">
    
    அம்மன் பாலகர் ஸ்ரீராமமூர்த்தி சுவாமிகள், அருள்தரும் காளிபராசக்தி தவசித்தர் பீடம்<br />
    சிந்தலக்கரை போஸ்ட், தூத்துக்குடி மாவட்டம், தமிழ்நாடு, இந்தியா<br />
    Pin : 628 902<br />
    Ph: 91 4632 271043, 271333, 271466
    </font>
    </div>
   
    <div className="col-md-12" align="center"><font className="bottommenu1">Copyright © Sinthalakarai vekkaliamman temple. All rights reserved</font></div>
    
    </div>
    
    </div>

    
  )
}

export default Footer; 