import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import Home from './screens/Home';
import Place from './screens/Place';
import Kovil from './screens/Kovil';
import History from './screens/History';
import Activity from './screens/Activity';
import Service from './screens/Service';
import Function from './screens/Function';
import Contact from './screens/Contact';


const getBasename = path => path.substr(0, path.lastIndexOf('/')); 

function App() {
  return (
    
    <div className="wrapper">

      <Router basename={getBasename(window.location.pathname)}>
      {/*<Router>*/}
      {/* <div className="content-wrap">  this div is for other contnet wrap apart from footer  to keep foolter always at bottom even if content less */}
        <Navbar />
        <Switch>
        <Route path="/" exact component={ () => <Home /> }/>
		  {/*again home called so that we can use  ahref to call home page for crousal load in navbar. if we call only "/" in ref it wont work after build*/}
		         
	<Route path="/home" exact component={ () => <Home /> }/>
	
	  <Route path="/place" exact component={ () => <Place /> }/>  
  <Route path="/kovil" exact component={ () => <Kovil /> }/>
  <Route path="/history" exact component={ () => <History/> }/>  
  <Route path="/activity" exact component={ () => <Activity/> }/>  
  <Route path="/service" exact component={ () => <Service/> }/>  
  <Route path="/function" exact component={ () => <Function/> }/>  
  <Route path="/contact" exact component={ () => <Contact/> }/>      
        </Switch>     {/*</div>*/}  
        <Footer /> {/* foolter outside to keep always at bottom even if content less */}
      </Router> 
      
 </div>
  );
}

export default App;
