import React from 'react';

function History() {
  return (
   <div> 
    

{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}

<div className="row text1">
<div className="col-md-12" align="left">&nbsp;</div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>

  <center><font className="bodyfontb">ஸ்தல மற்றும் சுவாமிகளின் வரலாறு</font></center>
  
  <br /><br />
  <b>அம்மன் பாலகர் ஸ்ரீராமமூர்த்தி சுவாமிகளின் திரு அவதாரம்</b><br /><br />
இந்த அருள்தரும் ஸ்ரீகாளிபராசக்தி தவசித்தர் பீடம் உருவாகியதற்கு முக்கிய மூலகர்த்தாவான அம்மன் பாலகர் ஸ்ரீராம மூர்த்தி சுவாமிகள் சித்தர்கள்
வாழ்ந்து வந்து பல அற்புதங்கள் நிகழ்த்திய சிந்தலக்கரை கிராமத்தில் வேலப்ப நாயக்கர்-சிலுக்கம்மாள் என்ற செல்வச் செழிப்புள்ள தம்பதியருக்கு
10வது குழந்தையாகத் திருஅவதாரம் செய்தார். கடைசிப் பிள்ளை என்பதால் குடும்பத்தில் செல்லம் அதிகம். சுவாமிகள் சீறோடும், சிறப்போடும்
பெற்றோர்களால் வளர்க்கப்பட்டார். சுவாமிகளின் பெற்றோர்கள் மிகுந்த தர்ம சிந்தனை உடையவர்கள். இந்நிலையில் சுவாமிகளின் ஐந்தாவது வயதில்
காலத்தின் கோலத்தால் அவருடைய அன்பு அன்னையார் தீராத நோயினால் பாதிக்கப்பட்டு இறைவனடி சேர்ந்தார். அதன்பின் சுவாமிகள் தாய்வழிப்
பாட்டியின் பராமரிப்பில் வளர்ந்தார். தாயாரின் ஏக்கம் தெரியாதவாறு தந்தையார் அன்புடன் கண்ணும் கருத்துமாய் வளர்த்து வந்தார்.

</div>

  <div className="col-md-4" align="center"><br /><img src="images/36_2.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  <b>இளம் வயதில் இறைசிந்தனை</b><br /><br />
  
ஸ்ரீராம மூர்த்தி சுவாமிகள் தமது ஐந்தாவது வயதில் சிந்தலக்கரை ஆரம்பப் பள்ளியில் சேர்க்கப்பட்டார். ஆனால் படிப்பதில் ஆர்வம் காட்டாமல் மற்ற
குழந்தைகளுடன் சேர்ந்து விளையாடுவதில் விருப்பம் காட்டினார். குறிப்பாகக் கோவில் கட்டி விளையாடுவதில் பாலகருக்கு விருப்பம் அதிகம்.
களிமண்ணால் அம்மனின் உருவத்தை செய்து மற்ற சிறுவர்களுடன் சேர்ந்து அதை வழிபடுவார். அவரது விளையாட்டுக்களும் அவரது பக்தியை
வெளிப்படுத்துபவைகளாகவே இருந்தன. ஊரில் கொடை விழா நடைபெறும் போது பூஜை நடைபெறுவதைக் கருத்துடன் கவனிப்பார். சுவாமிகளின்
இப்பருவத்தில் உடன் பிறந்த சகோதரிகள் அடுத்தடுத்து மரணமடையவே பாசம் காட்டப்பட வேண்டியவர் பழிச்சொல்லுக்கு ஆளானார். மேலும்
தாயாரின் மருத்துவச் செலவால் வறுமைச் சூழல் ஏற்பட்டது. விதி செய்த சதி போல் சுவாமிகளின் தந்தையாரும் இறைவனடி சேர்ந்தார். அப்பொழுது
சுவாமிகளுக்கு வயது 10. தந்தை காலமானவுடன் சுவாமிகளின் வாழ்வில் புயல் வீசத் தொடங்கியது. படிப்பு 5ம் வகுப்புடன் தடைபட்டது.
</div>

  <div className="col-md-4" align="center"><br /><img src="images/36_1.jpg" alt="" /></div>


</div>
</div>
 )
}

export default History;