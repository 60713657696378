import React from 'react';

function Service() {
  return (
   <div> 
    

{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}

<div className="row text1">
<div className="col-md-12" align="left">&nbsp;</div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>

  <center><font className="bodyfontb">சுவாமிகளின் தொண்டுகள்</font></center>
  

  <br /><br />
  இத்தவ சித்தர் பீடத்தில் நாள்தோறும் அமுதசுரபி போல் வற்றாது அன்னதானம் ஸ்ரீராம மூர்த்தி சுவாமிகளால் வழங்கப்பட்டு வருகிறது. சீரகம், மிளகு
கலந்த சுவைமிகு சீரக கஞ்சியும், கேப்பைக் கூழும் வரும் அனைவருக்கும் வழங்கப்படுகிறது. அன்றும் இன்றும் என்றும் தன்னை நாடி வரும் பக்தர்களுக்கு
அன்னதானம் செய்து கொண்டு வருகிறார்.


</div>

  <div className="col-md-4" align="center"><br /><img src="images/38.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  அருள்தரும் காளிபராசக்தி தவசித்தர் பீடத்தின் மூலமும் அரவிந்த் கண் மருத்துவமனையும் இணைந்து இலவச கண் சிகிச்சை முகாம் நடத்தி தொண்டு 
  செய்து வருகிறார்கள்.
</div>

  <div className="col-md-4" align="center"><br /><img src="images/40.jpg" alt="" /></div>


</div>
</div>
 )
}

export default Service;