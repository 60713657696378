import React from 'react';

function Activity() {
  return (
   <div> 
    

{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}

<div className="row text1">
<div className="col-md-12" align="left">&nbsp;</div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>

  <center><font className="bodyfontb">சுவாமிகளின் அற்புதங்கள்</font></center>
  

  <br /><br />
  அன்னையின் உத்தரவின் படி சுவாமிகள் 16 அடி நீள திரிசூல அலகைக் கன்னத்தில் குத்தி
அனல் பறக்கும் வெங்கலத் தீச்சட்டி ஏந்தி பூக்குழி இறங்கும் காட்சிகள்.

</div>

  <div className="col-md-4" align="center"><br /><img src="images/24_3.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  அம்மன் பாலகர் பறவை காவடியில் தொங்கிக் கொண்டு வரும் காட்சி.
</div>

  <div className="col-md-4" align="center"><br /><img src="images/22.jpg" alt="" /></div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  பறவை காவடியில் இருந்து கொண்டு சுவாமிகள் அருள் புரியும் காட்சி.
  </div>

  <div className="col-md-4" align="center"><br /><img src="images/23.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  சுவாமிகள் தன் தலையில் 108 தேங்காய்களை உடைத்து அம்மனுக்குப் படைக்கும் காட்சி.

</div>

  <div className="col-md-4" align="center"><br /><img src="images/8.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  அம்மனுக்கு உயிர்பலி கொடுப்பதைத் தடுப்பதற்காக தன்னை வருத்தி தன் கையிலும் காலிலும் ஆணியால் அறைந்து கன்னத்தில் திரிசூல அலகு குத்தி
அன்னையை வேண்டி தவம் செய்த காட்சி.
</div>

  <div className="col-md-4" align="center"><br /><img src="images/26.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  உலக உயிர்களின் நன்மைக்காக 3 அடி ஆழமும் 3 அடி அகலமுமுள்ள குழியில் சுவாமிகள் அமர்ந்து அதன் மேல் பலகை வைத்து மூடி, மணல் மற்றும்
வேப்பிலை மற்றும் பூக்களால் மூடி அம்மன் பாலகர் 61 மணி நேரம் பூமிக்குள் தவம் செய்யும் காட்சி. (குறிப்பு : சுவாமிகள் பூமி தவம் செய்யும்
போதுதான் அன்னை 27 நட்சத்திரங்களாகக் காட்சி தந்தாள். அந்த இடத்தில்தான் 27 நட்சத்திரக் கோரிக்கை நிறைவேற்றும் குலாயுத பீடம் உள்ளது. )

</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/27.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  பூமி தவ பூஜை முடித்துக் கொண்டு குழியிலிருந்து வெளி வந்து சுவாமிகள் ஆசி தரும் காட்சி.

</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/28.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  ஒரு சிறுவனுக்கு கை, கால்கள் முடமாக இருந்ததை சுவாமிகளிடம் அருள்வாக்கு பெற்று நிவர்த்தி அடைந்த காட்சி
</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/11.jpg" alt="" /></div>
 
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  அன்னை காளிபராசக்தி அருளால் தன் நெஞ்சில் சூலத்தைக் குத்திக் கொண்டு சுவாமிகள் அருள்வாக்கு சொல்லும் காட்சி.
</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/9.jpg" alt="" /></div>
 
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  அம்மன் பாலகர் ஸ்ரீராமமூர்த்தி சுவாமிகள் சித்தர்களின் அற்புதங்கள் நிகழ்த்திய காட்சிகள். உலக உயிர்களின் பாரங்களயும், துன்பங்களையும் தன்னில்
சுமக்கின்றார்.

</div>
  
  <div className="col-md-4" align="center"><br /><br /><img src="images/29.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  அம்மன் பாலகர் ஸ்ரீராமமூர்த்தி சுவாமிகளின் அருட்காட்சி.
</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/36.jpg" alt="" /></div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
 <b>அன்னை காளிபராசக்தி நேரில் வந்து பேசும் அற்புதம்</b> <br /><br />
அன்னை ஸ்ரீகாளிபராசக்தி தன்னை நாடி வரும் பக்தர்களுக்கு தன் பாலகனின் உருவில் செவ்வாய் மற்றும் வெள்ளிக்கிழமைகளில் நேரில் வந்து
பேசுகிறாள். அக்கிழமைகளில் மாலை 6 முதல் 8 மணிக்குள் அம்மனுக்கு அபிஷேகம் செய்து சிறப்பு பூஜை செய்த பின் சுவாமிகள் ஓலைக் குடிசை
பீடத்தில் அமர்ந்து கொண்டு அன்னையின் அருளால் அனைவருக்கும் அருள்வாக்கு வழங் குவார்கள். ஸ்ரீராம மூர்த்தி சுவாமிகளின் மங்கள வாக்கு
நித்தியானந்த ஊற்றாகப் பெருக்கெடுத்து பக்தர்களுக்கு நலம் சேர்க்கின்றது.
</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/32_1.jpg" alt="" /></div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  அம்மன் பாலகரிடம் அருள்வாக்கு கேட்கக் காத்துக் கிடக்கும் சுவாமியின் பக்தர்கள் அருள்வாக்கு கேட்கும் காட்சி.

</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/10.jpg" alt="" /></div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  சுவாமிகளிடம் ஆசி பெற வந்திருக்கும் பாலகரின் பக்தர் கூட்டம்.
</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/32.jpg" alt="" /></div>
</div>
</div>
 )
}

export default Activity;