import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import CarouselPage from '../layout/CarouselPage';

function Home() {
   return (
   <div> 
    
  
         <CarouselPage />
     
{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}
{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}

<div className="row text1">
<div className="col-md-12" align="left">&nbsp;</div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>

  <center><font className="bodyfontb">முன்னுரை</font></center>
  
  <br />
  ஆன்மீகம் செழித்தோங்குவதில் முதன்மை மிக்க தேசம் பாரதம். அத்தகைய நம் பாரதத்தில்
இறையருள் பெற்ற மகான்கள் மக்களை நல்வழிப்படுத்த அவ்வப்பொழுது அவதாரம்
செய்தார்கள். அவர்கள் ஒவ்வொரு காலத்திலும் தோன்றி மக்களை நன்னெறிப்படுத்தி
மக்களின் மனங்களில் நிறைந்திருப்பது இயல்பு. இந்நிலையில் உலக உயிரினங்கள்
ஒற்றுமையுடன் நலமுடன் இருக்கவும், தேசம் சுபீட்சம் பெறவும் நமக்காக கடுந்தவங்கள் பல
புரிந்து, அம்மனின் அருளைப் பெற வரும் பக்தர்களுக்கு உலகத்து நாயகியாம் அருள்தரும்
ஸ்ரீகாளிபராசக்தியின் அருளால் அருள்வாக்கு வழங்கி வரும் அம்மன் பாலகர் ஸ்ரீராமமூ
ர்த்தி சுவாமிகளின் அருள் தரும் ஸ்ரீகாளிபராசக்தி தவசித்தர் பீடம் தரிசித்து அனைவரும்
அருள் பெறுவோம்.<br /><br /><br /><br />
</div>

  <div className="col-md-4" align="center"><br /><img src="images/3.jpg" alt="" /></div> 


</div>
</div>
 )
 
}

export default Home;