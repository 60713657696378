import React from 'react';

function Contact() {
  return (
   <div> 
    

{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}


<div className="row">
<div className="col-md-12" align="left">&nbsp;</div>
 <div className="text col-md-1" align="right">&nbsp;</div>
 <div className="col-md-10">
   <div className="row  marg">
<div className="col-md-4 bodyfont" align="center">
        <div className="headerfont innerth" width="100%">உங்கள் கருத்துக்கள்</div>


                        <div className="bodyfont row1">
                        <p align="center" className="pwrapper1  bodyfont">
                        	
     					                 

  <br />அம்மன் பாலகர் ஸ்ரீராமமூர்த்தி சுவாமிகள், <br />அருள்தரும் காளிபராசக்தி தவசித்தர் பீடம்<br />
    சிந்தலக்கரை போஸ்ட், <br />தூத்துக்குடி மாவட்டம், <br />தமிழ்நாடு, இந்தியா<br />
    Pin : 628 902<br />
    Ph: 91 4632 271043, 271333, 271466
  
  <br /><br />Email: <a href="mailto:gkanns@gmail.com">gkanns@gmail.com</a>
                        </p>
                        </div><br />

      </div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
 
<div className="map-responsive" width="100%" height="450px">
  
     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3938.643601805561!2d78.01770591410596!3d9.186584989031996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b014e0a84af6757%3A0x98c310559ea43be6!2sSinthalakarai%2C%20Tamil%20Nadu%20628902!5e0!3m2!1sen!2sin!4v1609777935714!5m2!1sen!2sin" width="100%" 
     height="650" frameborder="0" style={{border: "0", marginTop: "-200px"}} allowfullscreen="" title="map"></iframe>

</div>


  </div>


 </div>
</div>
<div className="text col-md-1" align="right">&nbsp;</div>

</div>
</div>
 )
}

export default Contact;