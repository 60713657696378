import React from 'react';

function Function() {
  return (
   <div> 
    

{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}

<div className="row text1">
<div className="col-md-12" align="left">&nbsp;</div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>

  <center><font className="bodyfontb">நிகழ்ச்சிகள்</font></center>
  

  <br /><br />
  சித்ரா பௌர்ணமியன்று கலச வேள்வி சித்தர் பீடத்தில் அம்மன் பாலகர் ஸ்ரீராமமூர்த்தி சுவாமிகளால் நடத்தப்பட்டு வருகிறது

ஆனிமாதம் கடைசி செவ்வாய் கிழமையன்று சகல உயிர்கள் நலம் பெற சுவாமிகள் வேள்வி பூஜை செய்யும் காட்சி. இந்த வேள்வியின் பயனாக
சுவாமிகளின் ஆசியுடன் சகல ஐஸ்வர்யங்களும் கிடைக்கும்.

</div>

  <div className="col-md-4" align="center"><br /><img src="images/25.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  அன்றைய தினம் அம்மன் பாலகர் ஸ்ரீராம மூர்த்தி சுவாமிகள் அன்னையின் உத்தரவின் படி 16 அடி நீள திரிசூல அலகைக் கன்னத்தில் குத்தி அனல்
பறக்கும் வெங்கலத் தீச்சட்டி ஏந்தி 42 அடி நீளமுள்ள பூக்குழியில் இறங்குவார்.
</div>

  <div className="col-md-4" align="center"><br /><img src="images/24_3.jpg" alt="" /></div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  ஆனிமாதம் கடைசி செவ்வாய் கிழமையன்று சுவாமிகள் வெட்காளியம்மனுக்கு பால் அபிஷேகம் செய்து மலர் அலங்காரம் செய்யும் காட்சி.
ஆடிப்பூரம் அன்று அன்னைக்குக் கஞ்சி அபிஷேகம் செய்து, வளையல் பூட்டி சுவாமிகள் அன்னைக்கு வளைகாப்பு நடத்தும் காட்சி. மங்கையர்களுக்கு
மங்களபாக்கியம் கைகூடவும், குழந்தை செல்வம் கிடைக்கவும், திருமணத் தடை விலகவும் இந்த சிறப்பு மிக்க பூஜை நடைபெறுகிறது
</div>

  <div className="col-md-4" align="center"><br /><img src="images/39_1.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  புரட்டாசி மாதம் அம்பாளுக்கு நவராத்திரி பூஜை நடைபெறும். அப்பொழுது அன்னைக்கு அணையாவிளக்கு ஏற்றி அம்மன் பாலகர் ஸ்ரீராம மூர்த்தி
சுவாமிகள் வழிபடுவார்கள். சுவாமிகள் பால் அபிசேகம் செய்யும் காட்சி மனதிற்கு ஆத்மசாந்தியை தரும்.

</div>

  <div className="col-md-4" align="center"><br /><img src="images/39.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  தை மாதம் கடைசி வெள்ளிக்கிழமையன்று அம்மன் பாலகர் ஸ்ரீராம மூர்த்தி சுவாமிகள் உலக உயிர்களின் ஒற்றுமைக்காக 61 மணி நேரம் அல்லது 42
மணி நேரம் அல்லது 27 மணி நேரம் என்று காளிபராசக்தி அன்னை எந்த நேரத்தை உத்தரவு தருகிறாளோ அந்த நேரப்படி பூமி தவ பூஜையைத்
தொடங்குவார். உலக உயிர்களின் நலத்திற்காகவும், தேசம் சுபிட்ஷம் பெறுவதற்காகவும், நாடு நலம் பெறவும், மக்கள் அனைவரும்
ஒற்றுமையுணர்வுடன் இருப்பதற்காகவும், அம்மன் பாலகர் ஸ்ரீராம மூர்த்தி சுவாமிகள் வருடந்தோறும் பூமி தவத்தை மேற்கொள்கிறார். தை மாதம்
கடைசி வெள்ளிக்கிழமையன்றும் ஆனிமாதம் கடைசி செவ்வாய் கிழமையன்றும் சுவாமிகளின் பக்தர்கள் சிந்தலக்கரை அருள்தரும் காளிபராசக்தி
தவசித்தர் பீடத்திற்கு சக்தி மாலை அணிந்து 9 நாள் விரதமிருந்து இருமுடி கட்டிக் கொண்டு வருவார்கள் .
</div>

  <div className="col-md-4" align="center"><br /><img src="images/27_1.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
 <b> சக்திமாலை அணியும் முறைகள்</b><br /><br />
அம்மன் பாலகர் ஸ்ரீராம மூர்த்தி சுவாமிகளின் நாமத்தைச் சொல்லி சக்திமாலை அணிந்து வணங்கிடுவோர்க்கும், வெட்காளியம்மன் திருவுருவப்
படத்தை வீட்டில் வைத்து வழிபடுவோர்க்கும் இப்பூவுலகில் தொல்லை, பிணி தீர்த்து வானமும், வையகமும் புகழ மண்ணில் நடுநாயகியாய் திகழ்ந்து
வாணிபம், கைத்தொழில், விவசாயம் பெருகி நெறிமுறை நிலைத்து, தீராத வினையாவும் போக்கி, நல்லதொரு கல்வியும், செல்வமும், வீரமும், உ
ய்யவும் வரம் அளித்து பிள்ளைச் செல்வம் இல்லாதவர்க்கு மடிப்பிச்சை அளித்தும், மாங்கல்ய வரமும் தந்து சிந்தலக்கரை அருள்தரும் காளிபராசக்தி
அம்மன் கருணையுடன் அருள் புரிவாள்.<br /><br />
தை மாதம் கடைசி வெள்ளிக்கிழமையன்று பூமி தவ பூஜையின் பொழுதும், ஆனிமாதம் கடைசி செவ்வாய் கிழமையன்று தீச்சட்டி பூக்குழி நிகழ்ச்சியின்
பொழுதும் பக்தர்கள் சக்தி மாலை அணிந்து விழாவிற்கு முன் 9 நாள் ஒரு வேளை மட்டும் உணவு உட்கொண்டு விரதம் இருக்க வேண்டும். விரதம்
இருக்கும் பொழுது பச்சை அல்லது மஞ்சள் நிற உடை அணிய வேண்டும். சக்திமாலை அணிந்த நாளிலிருந்து 18 நாட்களுக்குள் சக்திமாலை அணிந்த
அனைவரும் இருமுடி கட்டிக் கொண்டு தவசித்தர் பீடத்திற்கு வந்து இருமுடியை அம்மனுக்கு செலுத்த வேண்டும். இருமுடியில் பூஜை பொருட்களை
முன்முடியில் தேங்காய் ஒன்றும், சிறிய தேன் பாட்டில் ஒன்றும், மஞ்சள், குங்குமம், பத்தி, சூடம், பன்னீர் சிறிய பாட்டில் மட்டும் வைத்துக் கட்ட
வேண்டும். பின்முடியில் 1/4 கிலோ பச்சரிசி, சீரகம் 50 கிராம் மற்றும் மிளகு 50 கிராம் வைத்துக் கட்ட வேண்டும். இவ்வாறு இருமுடி கட்டிக் கொண்டு
தவசித்தர் பீடத்திற்கு சக்தி மாலை அணிந்தவர்கள் வர வேண்டும். இவ்வாறு வருவோரின் இதய சுமைகளும், இன்னல்களும் நீங்கி அன்னை
காளிபராசக்தி அருளாலும், அம்மன் பாலகர் ஸ்ரீராமமூர்த்தி சுவாமிகளின் ஆசியாலும் நினைத்த காரியம் கைகூடும்.
மாதந்தோறும் பௌர்ணமியன்றும், அமாவாசையன்றும் சிறப்பு பூஜைகள் நடைபெறும்.

</div>

  <div className="col-md-4" align="center"><br /><br /><img src="images/33.jpg" alt="" /></div>
</div>
</div>
 )
}

export default Function;