import React from 'react';

function Place() {
  return (
   <div> 
    

{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}

<div className="row text1">
<div className="col-md-12" align="left">&nbsp;</div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>

  <center><font className="bodyfontb">சிந்தலக்கரை அருள் தரும் ஸ்ரீகாளிபராசக்தி தவசித்தர் பீடத்தின் இருப்பிடம்</font></center>
  

  <br /><br />
  பாரம்பரியமிக்க வரம் அருளும் புண்ணிய சக்தி பீடங்களில் ஒன்றுதான் சிந்தலக்கரை அருள் தரும் ஸ்ரீகாளிபராசக்தி தவசித்தர் பீடம். இந்த புண்ணிய
பூமியின் தாய் ஸ்ரீகாளிபராசக்தி. அவளே ஸ்ரீவெட்காளியம்மன் என்றும் அழைக்கப்படுகிறாள். <br /><br />எப்படி வேண்டுமானாலும் விசித்திரம் நடக்கும்
என்பதற்கு சாட்சியாக சிந்தலக்கரையில் மூ வுலகையும் ஆளும் அன்னையான ஸ்ரீகாளிபராசக்தி வந்து அமர்ந்து தன் பாலகன் ஸ்ரீராம மூர்த்தி
சுவாமிகளின் வடிவில் பல அற்புதங்கள் புரிந்து உலக உயிரினங்கள் அனைத்திற்கும் அருள் மழை பொழிகின்றாள்.
<br /><br />இந்த பீடம் தூத்துக்குடி மாவட்டத்தில் உள்ள பெருங்கவி பாரதி பிறந்த எட்டையாபுரம் அருகில் அருப்புக்கோட்டை நெடுஞ்சாலையில் 5 கிலோமீட்டர்
தொலைவில் அமைந்துள்ளது.
</div>

  <div className="col-md-4" align="center"><br /><img src="images/1.jpg" alt="" height="200"  width="200"/></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
<b>விஞ்ஞானமும் ஆன்மீகமும்</b><br /><br />
இங்கு ஸ்ரீராமமூர்த்தி சுவாமிகள் தவசித்தர் பீடம் அமைந்துள்ள இடத்தில்தான் சூரிய ஒளிக்கதிர்களை 24 மணி நேரமும் பதிவு செய்யும் தேசிய பௌதீக
ஆராய்ச்சி நிலையம் அமைந்துள்ளது. இந்த விஞ்ஞான கூடம் தலைநகர் டெல்லியில் உள்ள வி.எஸ்.ஆரின் நான்காவது கிளை தேசிய சோதைைனக்
கூடம் விண்வெளி ஆராய்ச்சிக்கு உலகின் புகழ் பெற்ற என்.ஜீ.ஆர்.ஐ(N.G.R.I) என்ற ஸ்தாபனத்தின் கீழ் செயல்படுகிறது.
இது பூமியில் காந்த சக்திகள் எவ்வாறு மாறுபடுகிறது என்பதை அளக்கும் இடம். இது பூமியின் வடக்கு-தெற்கு துருவ காந்த சக்தி சமமாகக் காணப்படும்
இடம். உலகின் சீதோஷ்ண நிலைமை, பருவ மாற்றங்கள், இயற்கையின் சீற்றங்கள் அனைத்தையும் கணக்கிடலாம். உலக விஞ்ஞானிகள் அற்புதங்கள்
நிகழ்த்தும் காந்த சக்தி, சூரிய சக்தி சங்கமிக்கும் இடமாக இதைத் தேர்வு செய்துள்ளார்கள். 
</div>

  <div className="col-md-4" align="center"><br /><img src="images/1_1.jpg" alt="" /></div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
இந்த ஆய்வுக்கூடத்தின் ஒட்டிய நிலத்திலேயே அன்னை
காளிபராசக்தியின் தவசித்தர் பீடம் அமைந்துள்ளது. ஆன்மீகமும், விஞ்ஞானமும் ஒரே இடத்தைத் தேர்வு செய்துள்ளது மிகப் பெரிய ஆச்சரியமாகும்.
சக்தியின் விளக்கம் என்ற பாடலில் பாரதியார் கூறுகிறார் :<br /><br />
மூலப் பழம் பொருளின் நாட்டம்-இந்த<br />
மூன்று புவியுமதன் ஆட்டம்<br />
காரிப் பெருங்களத்தின் மீதே-எங்கள்<br />
காளி நட மூலகக் கூட்டம்<br /><br />
ஆம். காளி தன் கடைக்கண் பார்வையில் சக்திகள் கூடும் இந்த இடத்தை தன் பாலகன் ஸ்ரீராம மூர்த்தி சுவாமிகள் மூலம் தேர்வு செய்து கொண்டாள்.
ஸ்ரீராம மூர்த்தி சுவாமிகள் காளியெனும் அளவற்ற ஆற்றலிடம் தம்மை முழுமையாக ஒப்படைத்து விட்டவராவார். பக்தி ஒன்றையே முழுமையாகக்
கொண்டு சாஸ்திர விதிப்படி இல்லாமல் அம்மனை நேருக்கு நேராகப் பார்த்து நம் அன்பிர்குரிய மனிதரிடம் எப்படி நடந்து கொள்கிறோமோ அப்படி
அம்மனை வழிபாடு செய்கிறார்.
அருள்தரும் காளிபராசக்தி தவசித்தர் பீடத்தில் அருள் புரியும் அன்னை ஸ்ரீகாளிபராசக்தி. இந்த அன்னையை அம்மன் பாலகர் களிமண்ணால்
வடிவமைத்து ஓலைக் குடிசையில் அமரச்செய்திருக்கிறார்.
</div>

  <div className="col-md-4" align="center"><br /><img src="images/1_2.jpg" alt="" /></div> 


</div>
</div>
 )
}

export default Place;