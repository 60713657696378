import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';

function Navbar() {
  const [open, setOpen] = React.useState(false);	
  return (
    <div>
		
<div align="center"  className="header1">
  <div className="row text1">

    <div className="col-md-3" align="right"><img src="images/logo.png"  alt="" className="img-responsive" align="top" /></div>
    <div className="col-md-8 headerfonthome">{/*<img src="images/welcome.png"  alt="" className="img-responsive" align="top" />*/}
    <br />சிந்தலக்கரை ஸ்ரீவெட்காளி அம்மன் பாலகர் ஸ்ரீராமமூர்த்தி சுவாமிகளை <br />தரிசிக்க அனைவரும் வாரீர்<br />
    <font className="sidemenuhead">சிந்தலக்கரை ஆனி மாத சக்தி மாலை இருமுடி விழா 2024 (<a href="https://sinthalakaraiswamy.com/php1/addmember.php"><font color="ffffff"><u>Online booking</u></font></a>)</font></div>
    {/*<font className="sidemenuhead">சிந்தலக்கரை காளிபராசக்தி உலக நலன் வார வழிபாட்டு மன்றம் (<a href="https://sinthalakaraiswamy.com/asp/addmember.asp"><font color="ffffff"><u>Online booking</u></font></a>)</font></div>*/}
  </div>
  
</div>
{/*<marquee  BGCOLOR="Pink"><Link to="admi.asp">Please click here for Online Application for Admission</Link>
</marquee>*/}

<nav className="navbar navbar-default navbar-static-top">

     <div className="text">

        <div className="navbar-header">
		<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" 
		  aria-expanded="false">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

        </div>
      </div>
     
      <div className="wrapmenu col-md-12">

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1"  align="right">


          <ul className="nav navbar-nav navbar-left">

          <li><NavLink to="/home" exact className="topmenu1" activeStyle={{color: "#1833E6"}} onClick={() => {setOpen(!open);window.location.href="home";}}>முன்னுரை</NavLink> </li>
                 
			
	<li><NavLink to="/place" className="topmenu1" activeStyle={{color: "#1833E6"}} onClick={() => {setOpen(!open);window.location.href="place";}}>இருப்பிடம்</NavLink></li>
		
	<li><NavLink to="/kovil" className="topmenu1" activeStyle={{color: "#1833E6"}} onClick={() => {setOpen(!open);window.location.href="kovil";}}>சிறப்புகள்</NavLink></li>
        
	<li><NavLink to="/history" className="topmenu1" activeStyle={{color: "#1833E6"}} onClick={() => {setOpen(!open);window.location.href="history";}}>ஸ்தல வரலாறு</NavLink></li>
		
	<li><NavLink to="/activity" className="topmenu1" activeStyle={{color: "#1833E6"}} onClick={() => {setOpen(!open);window.location.href="activity";}}>சுவாமிகளின் அற்புதங்கள்</NavLink></li>
			
	<li><NavLink to="/service" className="topmenu1" activeStyle={{color: "#1833E6"}} onClick={() => {setOpen(!open);window.location.href="service";}}>சுவாமிகளின் தொண்டுகள்</NavLink></li>                  
        
   <li><NavLink to="/function" className="topmenu1" activeStyle={{color: "#1833E6"}} onClick={() => {setOpen(!open);window.location.href="function";}}>நிகழ்ச்சிகள்</NavLink></li>     
   <li><NavLink to="/contact" className="topmenu1" activeStyle={{color: "#1833E6"}} onClick={() => {setOpen(!open);window.location.href="contact";}}>உங்கள் கருத்துக்கள்</NavLink></li>
         
          </ul>
        </div>
      </div>

</nav>
<hr className="new1" />
</div>
  )
}
export default Navbar;