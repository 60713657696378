import React from 'react';

function Kovil() {
  return (
   <div> 
    

{/*image slider ends
div align=center width=100%><img src="images/banner1.jpg" className="img-responsive"  name="ChangeBanner"></img></div*/}

<div className="row text1">
<div className="col-md-12" align="left">&nbsp;</div>

  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>

  <center><font className="bodyfontb">சிந்தலக்கரை அருள் தரும் ஸ்ரீகாளிபராசக்தி தவசித்தர் பீடத்தின் சிறப்புகள்</font></center>
  

  <br /><br />
  உயிர் பலியைத் தவிர்ப்பதற்காக அம்மன் பாலகர் அறுங் கோன சட்டத்தில் கையிலும் காலிலும் ஆணியால் அறைந்து கன்னத்தில் அலகு குத்தி தவம் செ
ய்த பொழுது ஸ்ரீவெட்காளி அம்மன் காட்சி தந்தாள்.<br /><br />
கல்வி, வீரம், செல்வம் இந்த மூன்று சக்திகளை ஒரு சேரப் பெற்ற அம்சமாக 42 அடி உயரத்தில் ஸ்ரீவெட்காளி அம்மனை சுவாமிகள் நிறுவியுள்ளார். இது
உலகிலேயே மிக உயரமான ஸ்ரீவெட்காளி அம்மன் சிலையாகும்.<br /><br />
சிந்தலக்கரை அருள் தரும் ஸ்ரீகாளிபராசக்தி தவசித்தர் பீடத்தில் உள்ள 27 நட்சத்திர சூலாயுத பீடத்தில் பக்தர்கள் தங்கள் ஊர் பெயர், நட்சத்திரம் மற்றும்
குறைபாடுகளை முறையிட்டு சீட்டில் எழுதி கட்டி விட்டால் அம்பாள் பக்தர்கள் குறைகளைப் போக்கிடுவாள். <br /><br />
இது பீடத்தின் சிறப்பாகும்

</div>

  <div className="col-md-4" align="center"><br /><img src="images/2.jpg" alt="" /></div>
  <div className="col-md-8 bodyfont" style={{textAlign: "justify"}}>
  <br /><br />
  
ராஜசயன பெருமாளின் சிலைக்குக் கீழே பூமிக்குள் பூமிபாலகர் சீனிவாசனின் சிலை அமைக்கப்பட்டுள்ளது. பூமி பாலகருக்கு சுவாமிகள் அபிஷேகம் 
செய்யும் காட்சி 
</div>

  <div className="col-md-4" align="center"><br /><img src="images/35.jpg" alt="" /></div>

 


</div>
</div>
 )
}

export default Kovil;